.tags-container {
  position: fixed;
  top: 0;
  /* left: 0; */
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 0;
  width: 70%;
}

.tags-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.tags-container input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 200px;
}

.tags-container button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tags-container button:hover {
  background-color: #0056b3;
}

.tags-container ul {
  list-style-type: none;
  padding: 0;
}

.tags-container li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.tags-container li span {
  flex-grow: 1;
  font-size: 18px;
}

.tags-container li input[type="text"] {
  margin-right: 10px;
  width: 150px;
}

button:disabled {
  background-color: #ccc; /* 禁用狀態的背景顏色 */
  color: #666; /* 禁用狀態的文字顏色 */
  cursor: not-allowed; /* 鼠標指標顯示為不可點擊 */
}
