.news-article-body .usdt-nav-wrapper {
  background-color: var(--news-article-nav-wrapper-bg);
}

.news-body {
  background: var(--theme-bg) no-repeat center center;
  background-size: cover;
  color: var(--news-body-color);
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0 auto;
  max-height: 100vh;
  overflow-y: auto;
}

.news-body .news-cover-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-color: var(--news-cover-image-bg-color);
}

.news-cover-image-img {
  height: auto; /* 自動調整高度以保持比例 */
  max-width: 100%;
  max-height: 100%;
}

/* 左+右 */
.news-body .news-container {
  background: var(--news-container-bg);
  border-top: 1px solid var(--news-container-border);
  border-radius: 12px;
  display: flex;
  padding: 40px 80px; /* 簡化 padding-top, padding-left, padding-right */
  /* box-sizing: border-box; */
  /* position: fixed; */
  position: relative; /* 改為相對定位 */
  /* top: 280px; */
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 0;
}

.news-body .main-content {
  /* max-height: 70vh;
  overflow-y: auto; */
}

.news-body .main-content-column {
  display: flex;
  flex-direction: column;
  flex: 3; /* 主內容佔 75% */
  min-height: 100vh;
  /* overflow-y: visible; */
}

/* 右側 */
.news-body .news-sidebar {
  margin-left: 36px;
  width: 22%; /* 360px */
  height: 64.5rem; /* 1032px */
  border: 1px solid var(--news-sidebar-border);
  border-radius: 12px;
  padding: 32px;
}

.news-body h1:not(.article-title) {
  color: var(--news-body-h1-color);
  margin-bottom: 5px;
}

.news-body .article-title {
  color: var(--news-body-article-title-color);
}

.news-body .publish-date {
  color: var(--news-body-publish-date-color);
  font-size: 14px;
  margin-bottom: 20px;
}

.news-body .content {
  background-color: var(--news-body-content-bg);
}

.news-body ul {
  padding-left: 20px;
}

.news-body li {
  margin-bottom: 10px;
}

.news-body .bullet-list {
  list-style-type: none;
  padding-left: 0;
}

.news-body .bullet-list li {
  position: relative;
  padding-left: 20px;
}

/* .news-body .main-content h1 {
  margin-top: 1.5em !important;
  margin-bottom: 1em !important;
} */

/* 
.news-body ul,
.news-body ol {
  color: var(--news-body-ul-color);
} */

.news-body .news-sidebar .news-sidebar-title {
  color: var(--news-body-sidebar-title-color);
  margin-bottom: 12px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  text-underline-position: from-font;
}

.news-body .news-sidebar .news-sidebar-keyword-title {
  color: var(--news-body-sidebar-title-color);
  margin-bottom: 12px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  text-underline-position: from-font;
  margin-top: 40px;
}

.news-body .main-content h2 {
  font-size: 16pt;
  color: #9bc887;
  margin-top: 1.2em !important;
  margin-bottom: 0.8em !important;
}

.news-body .main-content h3 {
  font-size: 15pt;
  color: #b4d1ed;
  margin-top: 1em !important;
  margin-bottom: 0.5em !important;
}

.news-body .main-content h4 {
  font-size: 13pt;
  color: #e16b69;
  margin-top: 1em !important;
  margin-bottom: 0.5em !important;
}

.news-body .main-content p:not(tbody p) {
  /* font-size: 16px;
  color: #e0e0e0; 
  line-height: 1.6; */
  font-size: 12pt;
  /* color: black; */
  color: var(--news-body-main-content-p-color);
  margin: 0 !important;
}

.news-body .news-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.news-body .news-sidebar li {
  margin-bottom: 15px;
}

.news-body .news-sidebar a {
  color: var(--news-body-sidebar-a-color);
  text-decoration: none;
}

.news-body .news-sidebar a:hover {
  text-decoration: none;
}

.news-body img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.news-body .news-container a {
  color: var(--news-body-a-color);
  text-decoration: none !important;
}

.news-body a:hover {
  text-decoration: none;
}

.news-content-divider {
  /* border-color: rgba(125, 162, 212, 0.2); */
  border-color: var(--news-content-divider-border);
  border-width: 1px;
  width: 100%;
  /* margin-bottom: 14px; */
}

.news-published-date .fa-clock {
  color: var(--news-published-date-color);
  margin-right: 4px;
}

.news-sidebar-article-card {
  display: block;
  text-decoration: none;
  color: inherit;
  flex-basis: 100%;
  margin-bottom: 12px;
  aspect-ratio: 5/2;
}

.news-sidebar-article-images {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.news-sidebar-article-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.news-sidebar-article-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px; /* 確保遮罩與圖片邊緣一致 */
  z-index: 1; /* 確保遮罩層在圖片上方 */
}

.news-sidebar-article-title-overlay {
  position: absolute;
  top: 0px;
  left: 5%;
  color: var(--news-article-title-overlay-color);
  background-color: var(--news-home-article-title-overlay-bg);
  padding: 5px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  z-index: 2;
}

.news-labels {
  display: flex;
  flex-wrap: wrap; /* 允許標籤自動換行 */
  gap: 8px;
  width: 100%; /* 確保寬度不會超出父容器 */
}

.news-labels::-webkit-scrollbar {
  display: none;
}

.news-label-button {
  background: transparent;
  border: 1px solid var(--news-label-button-border);
  border-radius: 100px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  text-underline-position: from-font;
  padding: 8px 16px;
  cursor: pointer;
  color: var(--news-label-button-color);
}

/* 針對小螢幕的調整 */
@media (max-width: 1024px) {
  .news-body .news-container {
    padding: 20px; /* 減少 padding */
    width: 100%; /* 確保寬度不超出 */
    box-sizing: border-box; /* 確保 padding 不會增加元素實際寬度 */
    overflow-x: hidden; /* 防止水平捲動 */
  }

  /* 調整側邊欄在小螢幕時的顯示 */
  .news-body .news-sidebar {
    display: none; /* 在小螢幕時隱藏側邊欄 */
  }

  /* 主內容區域在小螢幕時佔滿寬度 */
  .news-body .main-content-column {
    flex: 1;
    width: 100%;
  }
}

.news-info {
  display: flex;
  justify-content: space-between; /* 左右分開 */
  align-items: center; /* 垂直方向對齊 */
  /* padding: 10px; */
}
.news-published-date {
  align-items: center;
}
.news-info-left,
.news-info-right {
  display: flex;
  gap: 12px;
  align-items: center;
}

.share-icon {
  cursor: pointer; /* 鼠標懸停時顯示手形 */
}
.news-views {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.news-body .news-toc-menu {
  position: fixed; /* 改為固定定位 */
  right: 0px; /* 對應.news-container的padding-right */
  top: 50%; /* 置中 */
  border: 1px solid var(--news-toc-border);
  border-radius: 12px 0 0 12px; /* 只在左側添加圓角 */
  padding: 16px;
  background-color: var(--news-toc-bg);
  writing-mode: vertical-rl;
  text-orientation: upright;
  flex-direction: column; /* 垂直方向 */
}

.news-body .news-toc {
  position: fixed; /* 改為固定定位 */
  right: 0px; /* 對應.news-container的padding-right */
  top: 50%; /* 置中 */
  margin-left: 40px;
  width: 25%; /* 360px */
  border: 1px solid var(--news-toc-border);
  border-radius: 12px 0 0 12px; /* 只在左側添加圓角 */
  padding: 24px;
  overflow-y: auto; /* 內容超出時可滾動 */
  background-color: var(--news-toc-bg);
}

.news-body .news-toc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.news-body .news-toc-title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--news-toc-title-color);
}

.news-body .news-toc-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.news-body .toc-item:hover {
  background-color: var(--news-toc-item-hover-bg);
}

.news-body .toc-item.toc-h1 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--news-toc-title-color);
}

.news-body .toc-item.toc-h2 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration: none;
  color: var(--news-toc-h2-color);
}

.news-body table {
  width: 100%;           /* 或你想要的固定寬度 */
  table-layout: fixed;   /* 固定表格佈局，強制各欄等寬 */
  border: 1px solid var(--news-body-th-border); /* 表格邊框顏色 */
  border-collapse: collapse; /* 確保邊框不重疊 */

}

.news-body td {
  text-align: left;
  width: 100%;
  word-wrap: break-word; /* 確保長文字會自動換行 */
  border: 1px solid var(--news-body-th-border); /* 表格邊框顏色 */
}

.news-body tbody {
  font-family: 'Noto Sans TC'; /* 字體 */
  font-weight: 400; /* 字重 */
  font-size: 16px; /* 字體大小 */
  line-height: 19.2px; /* 行高 */
  letter-spacing: 0%; /* 字母間距 */
  font-family: 'Noto Sans TC'; /* 字體 */
}

.ql-table-block {
  padding: 12px;
}

.news-body tbody:has(tr:nth-child(2)) tr:first-child {
  background: #D4A100; /* 標頭底色 */
  color: #FFFFFF !important;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.news-body tbody:not(:has(tr:nth-child(2))) tr:first-child {
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}


.news-body td:first-child {
  font-family: 'Noto Sans TC';
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: 0%;
}


.ql-cell-focused::after, .ql-cell-selected-after, .ql-cell-selected::after, .ql-cell-focused::after {
  border: 1px solid #000000; /* 表格邊框顏色 */
  background: transparent;
}

