.usdt-nav-wrapper * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.usdt-nav-wrapper {
  font-family: Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* background-color: var(--usdt-nav-wrapper-bg); */
  background-image: var(--usdt-nav-wrapper-bg);

}

.usdt-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 64px;
}

.usdt-nav-left {
  display: flex;
  align-items: center;
}

.usdt-news-container {
  margin-left: 40px;
  display: flex;
  align-items: center;
}

.usdt-coin-container {
  display: flex;
  align-items: center;
  margin-left: 80px;
  margin-right: 40px;
}

.usdt-coin-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.active {
  color: var(--text-color);
  position: relative;
}

.usdt-nav-left .active::after {
  /* 在 active 元素下方添加一個虛擬元素 */
  content: "";
  position: absolute;
  bottom: -2px;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: var(--text-color);
}

.inactive {
  color: var(--text-color-inactive);
}

.usdt-coin-website {
  font-size: 20px;
  font-weight: bold;
  color: var(--website-text-color);
}

.usdt-coin-title {
  font-size: 20px;
  font-weight: bold;
}

.usdt-news-link {
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  margin-right: 16px;
  transition: color 0.3s;
}

.usdt-nav-right {
  display: flex;
  align-items: center;
}

.usdt-coin-container-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

body {
  padding-top: 64px; /* 與導航欄高度相同 */
}

.theme-toggle-container {
  height: 32px; /* TODO: rm*/
  width: 60px; /* TODO: rm*/
  border: 1px solid var(--theme-toggle-container-border);
  border-radius: 30px;
  background-color: transparent;
  align-items: center;
}

.theme-toggle-container.pc-only {
  display: flex;
  justify-content: center;
}

.theme-toggle-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s;
  color: var(--theme-toggle-button-color);
  margin: 0 4px;
}

.theme-toggle-button.active {
  background-color: var(--theme-toggle-button-active-bg);
}

.theme-toggle-button .icon {
  color: var(--theme-toggle-button-icon-color);
}

.usdt-nav-right .icon,
.menu-close-button .icon {
  color: var(--theme-toggle-button-icon-color);
}

.theme-toggle-button.active .icon {
  color: var(--theme-toggle-button-active-color);
}

.menu-button {
  display: none;
}
.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--nav-menu-bg);
  color: var(--nav-menu-color);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 20px;
}

.menu-header .menu-close-button {
  background: none;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

.menu-list li {
  padding: 24px 20px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  background-color: var(--nav-menu-list-bg);
  margin-bottom: 4px;
}

.menu-item {
  display: flex; /* 使用 flexbox 來對齊內容 */
  align-items: center; /* 垂直居中 */
  margin-left: 24px;
  margin-right: 24px;
}

.menu-list li a {
  color: var(--nav-menu-color);
  text-decoration: none;
  font-size: 18px;
  flex-grow: 1;
}

.menu-item .menu-item-link:hover {
  color: #ffcc00;
}

.menu-list li .menu-item-icon {
  color: var(--nav-menu-color);
  font-size: 18px;
  margin-left: 10px;
}

.menu-list li:hover .menu-item-icon {
  color: #ffcc00;
}

/* 針對小螢幕的調整 */
@media (max-width: 1024px) {
  .usdt-news-container,
  .usdt-price-container {
    display: none;
  }

  .theme-toggle-container.pc-only {
    display: none; /* 隱藏主題切換按鈕 */
  }

  .menu-button {
    display: flex; /* 顯示選單按鈕 */
    align-items: center;
    cursor: pointer;
  }

  .theme-toggle-container.mobile-only {
    display: flex;
    justify-content: flex-end;
    margin-left: auto; /* 靠右 */
    margin-right: 24px;
    margin-bottom: 24px;
  }
}
