.admin-dashboard-sidebar {
  width: 200px;
  background-color: #181a1f;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

.admin-dashboard-sidebar h2 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: bold;
}

.admin-dashboard-sidebar nav a {
  color: #888;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
  padding: 10px;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 14px;
}

.admin-dashboard-sidebar nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.admin-dashboard-sidebar nav a.active {
  background-color: #1a73e8;
  color: #ffffff;
}

