.manage-posts-app-wrapper {
  background-color: #181a1f;
  position: fixed;
  top: 0;
  /* left: 0; */
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 0;
  width: 80%;
}

.manage-posts {
  font-family: Arial, sans-serif;
  background-color: #181a1f;
  color: #ffffff;
  min-height: 100vh;
  padding-top: 64px;
}

.manage-posts__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 100px;
}

.manage-posts__title {
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 40px;
}

.manage-posts__article-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
  margin-bottom: 40px;
}

.manage-posts__article-card {
  background-color: transparent;
  overflow: hidden;
  position: relative;
  display: block;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out;
  height: 400px;
}

.manage-posts__article-images {
  position: relative;
  height: 225px;
  overflow: hidden;
}

.manage-posts__article-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.manage-posts__article-content {
  padding: 15px 0;
  background-color: transparent;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(400px - 225px - 30px);
}

.manage-posts__article-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: left;
}

.manage-posts__article-excerpt {
  font-size: 14px;
  margin-bottom: 0;
  color: #888;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.manage-posts__article-date {
  font-size: 12px;
}

@media (max-width: 768px) {
  .manage-posts {
    padding-top: 64px;
  }

  .manage-posts__container {
    padding: 20px;
  }

  .manage-posts__title {
    font-size: 24px;
    padding-left: 0;
  }

  .manage-posts__article-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .manage-posts__article-title {
    font-size: 16px;
  }

  .manage-posts__article-excerpt {
    font-size: 12px;
  }
}

.filter-buttons {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.filter-buttons button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: white;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-buttons button:hover {
  background-color: #f5f5f5;
}

.filter-buttons button:active {
  transform: scale(0.98);
}

/* 當按鈕被選中時的樣式 */
.filter-buttons button.active {
  background-color: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

/* 第一個標題不需要頂部間距 */
.manage-posts__title:first-of-type {
  margin-top: 0;
}

.manage-posts__article-buttons {
  display: flex;
  gap: 12px;
}

.manage-posts__edit-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.manage-posts__edit-button:hover {
  background-color: #0056b3;
}
