.categories-container {
  position: fixed;
  top: 0;
  /* left: 0; */
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 0;
  width: 70%;
}

.categories-container h1 {
  margin-bottom: 20px;
  color: #333;
}

/* 新增分類的輸入區域 */
.categories-container input[type="text"] {
  padding: 8px 12px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.categories-container button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 8px;
}

.categories-container button:hover {
  background-color: #45a049;
}

.categories-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 分類列表樣式 */
.categories-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* 主分類項目 */
.category-item {
  margin: 10px 0;
  padding: 12px;
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.category-item:hover {
  background: #ebebeb;
}

/* 子分類項目 */
.subcategory-item {
  margin: 5px 0;
  padding: 8px 12px;
  background: #fff;
  border: 1px solid #eee;
  margin-left: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.subcategory-item:hover {
  background: #f9f9f9;
}

/* 拖曳把手 */
.drag-handle {
  cursor: move;
  margin-right: 15px;
  color: #666;
  font-size: 16px;
}

/* 分類名稱 */
.category-item span:not(.drag-handle),
.subcategory-item span:not(.drag-handle) {
  flex: 1;
  margin-right: 15px;
}

/* 編輯按鈕樣式 */
.category-item button,
.subcategory-item button {
  padding: 6px 12px;
  margin-left: 8px;
  background-color: #2196f3;
  font-size: 12px;
}

.category-item button:hover,
.subcategory-item button:hover {
  background-color: #1976d2;
}

/* 刪除按鈕樣式 */
.category-item button.delete,
.subcategory-item button.delete {
  background-color: #f44336;
}

.category-item button.delete:hover,
.subcategory-item button.delete:hover {
  background-color: #d32f2f;
}

/* 拖曳時的樣式 */
.dragging {
  opacity: 0.5;
}

/* 儲存按鈕 */
.save-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.save-button:hover {
  background-color: #45a049;
}

.save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 編輯模式的輸入框 */
.edit-input {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
}

/* 錯誤訊息樣式 */
.error-message {
  color: #f44336;
  margin-top: 5px;
  font-size: 14px;
}
