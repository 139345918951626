.exchange-rates-page {
  background: var(--theme-bg) no-repeat center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-color);
  overflow-y: auto;
  z-index: 0;
}
/* 切換按鈕區容器 */
.action-buttons {
  margin-top: 96px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  gap: 2px;
}

.action-button {
  padding: 10px 15px;
  background-color: var(--action-button-bg);
  color: var(--action-button-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  text-underline-position: from-font;
}

.action-button:hover {
  background-color: var(--action-button-hover-bg);
  color: var(--action-button-hover-color);
}

/* 新增一個包裝容器 */
.rate-and-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  margin: 20px auto;
  max-width: 800px;
}

/* 預設金額按鈕 */
.preset-buttons {
  display: flex;
  justify-content: space-between; /* 均分可用空間 */
  gap: 4px; /* 按鈕之間的間隙 */
  margin-top: 8px;
  margin-bottom: 16px;
  width: 90%; /* 總寬度占90% */
}

.preset-button {
  flex: 1; /* 每個按鈕均分可用寬度 */
  border: 1px solid var(--preset-button-border);
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  height: 2.125rem;
  background-color: transparent;
  color: var(--preset-button-color);
}

.exchange-rates-updated_time {
  margin-top: 16px;
  border-top: 1px solid var(--exchange-rates-updated_time-border);
  border-radius: 16px; /* 設置圓角 */
  background-color: var(--exchange-rates-updated_time-bg);
}

.crypto-list-container {
  margin-top: 2px;
  background-color: var(--crypto-list-container-bg);
  padding-top: 16px;
  height: 100%;
}

/* 列表容器 */
.crypto-list {
  width: 80%;
  margin-top: 24px;
  padding: 10px;
  color: var(--crypto-list-color);
}

.crypto-header,
.crypto-item {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1.5fr 1.5fr 1fr;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* 排名居中對齊 */
.crypto-header > div:first-child,
.crypto-item > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crypto-header {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  color: var(--crypto-header-color);
}

/* 交易所靠左對齊 */
.exchange-logo-name {
  display: flex;
  justify-content: flex-start;
}

/* 手續費靠左對齊 */
.crypto-header > div:nth-child(3),
.crypto-item > div:nth-child(3) {
  display: flex;
  justify-content: flex-start;
}

/* 匯率居中對齊 */

/* 可獲得 USDT 居中對齊 */
.crypto-header > div:nth-child(5),
.crypto-item > div:nth-child(5) {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

/* 購買連結靠右對齊 */
.crypto-header > div:last-child,
.crypto-item > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.exchange-logo {
  width: 30px; /* 設置一個固定寬度 */
  height: 30px; /* 設置一個固定高度 */
  object-fit: contain; /* 確保圖片完整顯示且不變形 */
  margin-right: 10px; /* 在 logo 和文字之間添加一些間距 */
}

.exchange-name {
  font-size: 1.2rem;
  color: var(--exchange-name-color);
}

.crypto-rank {
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--crypto-rank-color);
  text-align: center;
}

.crypto-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px var(--crypto-item-hover-box-shadow);
}

/* 匯率顏色 */
.rate-info.updated {
  color: var(--rate-info-updated-color);
  font-weight: bold;
}

/* 價格及按鈕區域 */
.price-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.buy-link {
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.price.updated {
  color: var(--price-updated-color);
  font-weight: bold;
}

/* 購買按鈕 */
.buy-button {
  padding: 5px 10px;
  background-color: var(--buy-button-bg);
  color: var(--buy-button-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* 高亮顯示第一名 */
.crypto-item.highlighted {
  background: var(--crypto-item-highlighted-bg);
  font-weight: bold;
}

.price-info.highlighted {
  color: var(--price-info-highlighted-color);
}

/* 主容器 */
/* .coin-container {
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.coin-logo {
  width: 30px;
  height: auto;
  margin-right: 15px;
} */

.update-info {
  text-align: center;
  font-size: 12px;
  color: var(--update-info-color);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-top: 8px;
  padding-bottom: 8px;
}

.updated-time-countdown {
  color: var(--updated-time-countdown-color);
  font-weight: 700;
}

.last-update-time {
  margin-top: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--last-update-time-color);
}

.info-icon {
  margin-right: 10px;
}

.price-info,
.fee-info {
  font-size: 14px;
}

.fee-explanation {
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  text-align: center;
  text-underline-position: from-font;
  color: var(--fee-explanation-color);
  text-align: center;
}

.info-icon-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  color: var(--info-icon-wrapper-color);
}

.info-icon {
  font-size: 14px;
  color: var(--info-icon-color);
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--tooltip-bg);
  color: var(--tooltip-color);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

.input-mode-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  text-underline-position: from-font;
  margin-top: 16px;
  color: var(--input-mode-container-color);
}

.input-mode-text {
  color: var(--input-mode-text);
}

button {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.action-button.active,
.action-button.touched {
  background-color: var(--action-button-active-bg);
  color: var(--action-button-active-color);
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--input-container-border);
  border-radius: 16px;
  background-color: var(--input-container-bg);
  width: 600px; /* TODO: 改成rm */
  height: 212px; /* TODO: 改成rm */
}

.toggle-buttons {
  display: flex;
  border: 2px solid var(--toggle-buttons-border);
  background-color: var(--toggle-buttons-bg);
  overflow: hidden;
  height: 30px;
  width: 148px;
  margin-top: 16px;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  border-radius: 100px;
}

.toggle-button {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  color: var(--toggle-button-color);
  background-color: transparent;
  border: none;
  border-radius: 200px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 30px;
}

.toggle-button.active {
  color: var(--toggle-button-active-color);
  background-color: var(--toggle-button-active-bg);
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--input-wrapper-border);
  padding-bottom: 12px;
  width: 90%;
  margin-top: 20px;
  position: relative; /* 為絕對定位提供參考點 */
}

.amount-input {
  position: absolute; /* 使用絕對定位 */
  left: 50%; /* 水平置中 */
  transform: translateX(-50%); /* 修正水平置中位置 */
  color: var(--amount-input-color);
  font-family: Arial;
  font-size: 32px;
  font-weight: 700;
  line-height: 36.8px;
  text-underline-position: from-font;
  border: none;
  outline: none;
  background: transparent;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.currency-unit {
  color: var(--currency-unit-color);
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: auto;
}

/* 新增的 mobile-only class */
.crypto-list.mobile-only {
  display: none; /* 預設隱藏，僅在小螢幕顯示 */
}

/* ==== 響應式設置 ==== */
/* 基響應式設置 */
.rate-and-input-wrapper,
.crypto-list,
.exchange-info-list,
.fee-explanation {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
}

/* 針對小螢幕的調整 */
@media (max-width: 1024px) {
  .action-buttons {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 8px;
    justify-content: flex-start; /* 確保按鈕從左側開始排列 */
    margin-left: 10%;
  }

  .action-buttons::-webkit-scrollbar {
    display: none;
  }

  .input-container {
    /* width: 328px; */
    width: 80%;
    height: 217.42px;
    /* position: absolute; */
    gap: 0px;
  }

  .amount-input {
    font-size: 24px;
  }

  .crypto-list.pc-only {
    display: none; /* 隱藏 Header */
  }

  .crypto-list.mobile-only {
    display: flex;
    flex-direction: column; /* 垂直排列所有 .crypto-item */
    width: 80%; /* 保持寬度佔滿父容器 */
    max-width: 100%; /* 確保不超出螢幕 */
    margin-top: 16px;
  }
  .crypto-item {
    height: 128px;
    padding: 8px;
    display: flex; /* 使用 flex 布局 */
    align-items: flex-start; /* 讓內容靠上對齊 */
  }

  .crypto-item-left {
    display: flex;
    align-items: center;
    flex: 1; /* 分配 1 份寬度 */
  }

  .crypto-item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start; /* 添加這行使內容靠左對齊 */
    flex: 7; /* 分配 7 份寬度 */
    gap: 16px; /* 添加間距 */
  }

  .crypto-item-right-top {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex: 1; /* 分配剩餘空間 */
    width: 100%; /* 確保寬度填滿父元素 */

    /* margin-bottom: 8px; */
  }

  .mobile-buy-button {
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--crypto-list-color);
  }

  .crypto-item-right-bottom {
    flex-direction: column;
    display: flex;
    /* gap: 8px; */
    flex: 2; /* 分配剩餘空間 */
    width: 100%; /* 確保寬度填滿父元素 */
    justify-content: space-between;
  }

  .crypto-item-right-row1,
  .crypto-item-right-row2,
  .crypto-item-right-row3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .fee-info-text,
  .rate-info-text,
  .price-info-text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.fee-info {
  white-space: pre-line;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
