.news-home-app-wrapper {
  background: var(--theme-bg) no-repeat center center;
  background-size: cover;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 0;
}
.news-home {
  font-family: Arial, sans-serif;
  color: var(--news-home-color);
  min-height: 100vh;
  padding-top: 64px;
}

.news-home__container {
  max-width: 100%;
  margin: 0 auto;
}

.news-home__title {
  color: var(--news-home-title-color);
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  line-height: 32.81px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.news-home_content {
  background: var(--news-home-content-bg);
  border-top: 1px solid var(--news-home-content-border);
  border-radius: 16px;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  min-height: 100vh;
}

.news-home .mobile-only {
  display: none;
}

/* 左側 */
.news-home__article-grid.pc-only {
  width: 75%;
  display: flex; /* 新增：使用 flexbox 來排列子元素 */
  flex-wrap: wrap; /* 允許換行 */
  gap: 24px;
  height: fit-content;
}

/* 右側 */
.news-home__sidebar.pc-only {
  margin-left: 40px;
  width: 25%; /* 360px */
  /* height: 64.5rem; */
  border: 1px solid var(--news-home-sidebar-border);
  border-radius: 12px;
  padding: 32px;
}

.news-home__article-card {
  display: block;
  text-decoration: none;
  color: inherit;
}

.news-home__article-card:first-child {
  flex-basis: 100%;
  aspect-ratio: 5/2;
}

.news-home__article-grid.pc-only .news-home__article-card:not(:first-child) {
  width: calc(50% - 12px);
  margin-bottom: 24px;
  aspect-ratio: 1/1;
}

.news-home__article-images.first {
  position: relative;
  overflow: visible;
}

.news-home__article-images.not-first {
  position: relative;
  overflow: visible;
}

.news-home__article-main-image.first {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  aspect-ratio: 5 / 2;
  border-radius: 10px;
}

.news-home__article-main-image.not-first {
  /* position: absolute; */
  aspect-ratio: 16/9; /* 長寬比 */

  /* top: 0; */
  /* left: 0; */
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 10px;
}

.news-home__article-content {
  position: relative;
}

.news-home__labels {
  margin-top: 18px;
  margin-bottom: 16px;
  padding: 14px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.news-home__label-button {
  background: transparent;
  border: 1px solid var(--news-home-label-button-border);
  border-radius: 100px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  text-underline-position: from-font;
  padding: 8px 16px;
  cursor: pointer;
  color: var(--news-home-label-button-color);
}

.news-home__label-button.active {
  background: var(--news-home-label-button-active-bg);
}

.news-home__article-title-overlay {
  position: absolute;
  bottom: 20px;
  left: 2%;
  color: var(--news-home-article-title-overlay-color);
  padding: 5px;

  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.news-home__article-date-overlay {
  position: absolute;
  top: 12px;
  left: 14px;
  color: var(--news-home-article-date-overlay-color);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  padding: 6px 8px; /* 修改：上下6px，左右8px */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.news-home__article-views-overlay {
  position: absolute;
  bottom: 12px;
  right: 27px;
  color: var(--news-home-article-date-overlay-color);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  padding: 6px 8px; /* 修改：上下6px，左右8px */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.icon-clock {
  margin-right: 5px;
}

.news-home__article-title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--news-home-article-title-color);
  margin-top: 16px;
  margin-bottom: 8px;
}

.news-home__article-excerpt {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--news-home-article-excerpt-color);
  max-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制顯示3行 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* 顯示省略號 */
}

.search-bar {
  display: flex;
  align-items: center;
  /* width: 296px; */
  /* height: 48px; */
  padding: 12px 20px;
  gap: 13px;
  border-radius: 100px;
  border: 1px solid var(--search-bar-border);
}

.search-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  background: transparent;
  color: var(--search-input-color);
}

.search-icon {
  color: var(--search-icon-color);
}

.category-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  color: var(--category-title-color);
  margin-left: 0;
}

.hot-article-divider {
  /* border: 1px solid var(--hot-article-divider-border); */
  margin-top: 40px;
  margin-bottom: 40px;
}

.hot-article-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  color: var(--category-title-color);
  margin-left: 0;
}

.news-home__sidebar-article-card {
  display: block;
  text-decoration: none;
  color: inherit;
  flex-basis: 100%;
  margin-bottom: 12px;
}

.news-home__sidebar-article-images {
  position: relative;
  padding-bottom: 40%;
  height: 0;
  overflow: hidden;
}

.news-home__sidebar-article-main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.news-home__sidebar-article-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px; /* 確保遮罩與圖片邊緣一致 */
  z-index: 1; /* 確保遮罩層在圖片上方 */
}

.news-home__sidebar-article-title-overlay {
  position: absolute;
  top: 0px;
  left: 5%;
  color: var(--news-home-article-title-overlay-color);
  padding: 5px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  z-index: 2; /* 確保文字層在遮罩上方 */
}

.news-home-category-item {
  margin-top: 20px;
}

.news-home-category-button {
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.4px;
  color: var(--category-button-color);
  background: none;
  border: none;
  cursor: pointer;
  gap: 12px;
  padding-left: 0 !important;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.news-home-category-content {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  color: var(--category-content-color);
  list-style: none;
  padding: 0;
  margin-left: 0;
  margin-top: 12px;
  /* display: none; 初始隱藏 */
  list-style-type: disc; /* 使用圓點作為項目符號 */
  padding-left: 20px; /* 增加左邊距以顯示圓點 */
  display: block;
}

.news-home-category-content li {
  margin-bottom: 4px;
  cursor: pointer;
}

.hot-article-divider2 {
  display: none;
}

/* 針對小螢幕的調整 */
@media (max-width: 1024px) {
  .news-home .mobile-only {
    display: block; /* 在小螢幕顯示 */
  }

  .news-home .pc-only {
    display: none; /* 在小螢幕顯示 */
  }

  .news-home_content {
    padding-top: 8%;
    flex-direction: column; /* 新增：設置垂直方向排列 */
  }

  .news-home__labels {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 8px;
    justify-content: flex-start; /* 確保按鈕從左側開始排列 */
    margin-left: 10%;
  }

  .news-home__labels::-webkit-scrollbar {
    display: none;
  }

  /* .mobile-only .news-home__article-card {
    width: 90%;
  } */

  .news-home__sidebar.mobile-only {
    height: auto; /* 讓高度自動依內容調整 */
    width: 100%; /* 配合其他 mobile 元素的寬度 */
    margin: 0 auto; /* 置中顯示 */
  }

  .news-home__article-card {
    width: 100%; /* 設定卡片寬度 */
    margin: 0 auto 20px; /* 置中並加入底部間距 */
  }

  .news-home__article-images {
    width: 100%; /* 限制圖片容器寬度 */
    margin: 0 auto; /* 置中���片容器 */
    position: relative;
    overflow: visible;
  }

  .news-home__article-main-image {
    max-width: 100%; /* 確保圖片不會超出容器 */
    height: auto; /* 保持圖片比例 */
    border-radius: 12px;
  }

  .news-home__article-title-overlay {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;

    position: absolute;
    bottom: 0px;
    left: 5%;
    color: var(--news-home-article-title-overlay-color);
    /* background-color: var(--news-home-article-title-overlay-bg); */
    padding: 5px;
  }

  .hot-article-divider2 {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    border: 1px solid var(--news-home-mobile-hot-article-divider-color);
  }
}

/* 新增無文章提示的樣式 */
.news-home__no-articles {
  width: 100%;
  text-align: center;
  padding: 50px 0;
  font-family: Roboto;
  font-size: 18px;
  color: var(--news-home-color);
}

.news-home__other-article-card {
  display: flex;
  gap: 16px;
  text-decoration: none;
  color: inherit;
  background: var(--news-home-content-bg);
  border-radius: 8px;
  margin-bottom: 16px;
  height: 128px;
}

.news-home__other-article-card .news-home__article-images {
  flex: 0 0 128px; /* 固定寬度 */
  height: 128px; /* 與寬度相同的高度 */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.news-home__other-article-card .news-home__article-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-home__article-text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.news-home__article-last-line {
  display: flex;
  justify-content: space-between;
  color: var(--news-home-article-date-color);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.news-home__article-date .icon-clock {
  margin-right: 4px;
}

.news-home__other-article-card .news-home__article-title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--news-home-article-title-color);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 改為2行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}

.news-home__other-article-card .news-home__article-excerpt {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--news-home-article-excerpt-color);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}
