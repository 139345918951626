.news-edit-body {
  background-color: #f5f5dc;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 0;
  width: 80%;
}

.article-edit-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5dc; /* 淺米色背景 */
  box-sizing: border-box;
}

.page-title {
  color: #333333; /* 深色標題 */
  margin-bottom: 20px;
}

.editor-container {
  width: 80%;
  max-width: 800px;
  background-color: #1e1e1e; /* 深色編輯器背景 */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}

.title-input {
  width: 100%;
  padding: 10px;
  max-width: 800px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  color: #ffffff;
  font-size: 18px;
}

.title-input::placeholder {
  color: #666;
  font-style: normal;
  font-size: 16px;
}

.excerpt-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  color: #e0e0e0;
}

.seo-description-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  color: #e0e0e0;
}

.seo-keywords-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  color: #e0e0e0;
}

.slug-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  color: #e0e0e0;
}

.table_of_contents {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  color: #e0e0e0;
}

.content-editor {
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #444444;
  border-radius: 4px;
}

.ql-toolbar {
  background-color: #2b2b2b;
  border-color: #444444;
}

.ql-snow .ql-picker {
  color: #e0e0e0;
}

.ql-snow .ql-stroke {
  stroke: #e0e0e0;
}

.ql-snow .ql-fill {
  fill: #e0e0e0;
}

.ql-editor ul,
.ql-editor ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.ql-editor li {
  font-size: 16px;
  color: #b7bdc6; /* 列表項文字顏色，較暗 */
  line-height: 1.6;
  margin-bottom: 0.5em;
}

/* 為了確保所有列表項都有正確的顏色，包括嵌套列表 */
.ql-editor ul li,
.ql-editor ol li {
  color: #b7bdc6;
}

.ql-editor h1 {
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
  padding-left: 15px;
  position: relative;
  margin-bottom: 20px;
}

.ql-editor h1::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #fcd535;
}

/* 保持鏈接的顏色 */
.ql-editor a {
  color: #fcd535;
  text-decoration: none;
}

.ql-editor a:hover {
  text-decoration: underline;
}

/* 如果有引用塊，我們可以給它一個稍微不同的顏色 */
.ql-editor blockquote {
  border-left: 4px solid #fcd535;
  padding-left: 16px;
  color: #cccccc;
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.editor-button {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-button {
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
}

.save-button:hover {
  background-color: #f0f0f0;
}

.publish-button {
  background-color: #ffd700; /* 黃色 */
  color: #333;
  margin-left: 15px;
}

.publish-button:hover {
  background-color: #ffc800;
}

.ql-editor.ql-blank::before {
  color: #666;
  font-style: normal;
  font-size: 16px;
}

.ql-editor h1 {
  margin-top: 1.5em !important;
  margin-bottom: 1em !important;
}

.ql-editor h2 {
  /* border-left: 4px solid #fff;
  padding-left: 10px;
  margin-top: 30px; */
  font-size: 15pt !important;
  color: #9bc887 !important;
  margin-top: 1.2em !important;
  margin-bottom: 0.8em !important;
}

.ql-editor h3 {
  font-size: 15pt !important;
  color: #b4d1ed !important;
  margin-top: 1em !important;
  margin-bottom: 0.5em !important;
}

.ql-editor h4 {
  font-size: 13pt !important;
  color: #e16b69 !important;
  margin-top: 1em !important;
  margin-bottom: 0.5em !important;
}

.ql-editor p {
  /* font-size: 16px;
  color: #e0e0e0; 
  line-height: 1.6; */
  /* font-size: 12pt; */
  /* color: black; */
  /* color: #e0e0e0;  */
  font-size: 12pt !important;
  margin: 0 !important;
}

.ql-editor th,
.ql-editor td {
  border: 1px solid #e0e0e0; /* 表格邊框顏色 */
  padding: 8px;
  text-align: left;
}

.image-upload-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;
  text-align: center;
  /* background-color: #f9f9f9; */
}

.image-upload-input {
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ffcc00;
  border-radius: 5px;
  cursor: pointer;
}

.news-editor-tags-container {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2b2b2b;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  color: #e0e0e0;
}

.news-editor-tags-container-header {
  display: flex;
  align-items: center;
  gap: 25px;
}

.available-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
}

.tag-item {
  background-color: #e0e0e0;
  color: #333;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.selected-tag {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  display: flex;
  align-items: center;
}

.selected-tag button {
  background: none;
  border: none;
  color: white;
  margin-left: 5px;
  cursor: pointer;
}

.news-editor-category-container {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.news-editor-category-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.categories-list {
  margin-bottom: 15px;
  color: #333;
}

.category-group {
  margin-bottom: 10px;
}

.parent-category {
  padding: 8px;
  background-color: #90caf9;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}

.parent-category:hover {
  background-color: #64b5f6; /* hover 時的顏色也相應調整為更深的藍色 */
  /* 如果使用綠色，可以用 #66bb6a */
}

.expand-icon {
  margin-right: 8px;
  font-size: 12px;
}

.sub-categories {
  margin-left: 20px;
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.sub-category-item {
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  background-color: rgba(227, 242, 253, 0.3); /* 使用 rgba 設定透明度 */
}

.sub-category-item.selected {
  background-color: #e3f2fd;
  position: relative;
}

.sub-category-item.selected::after {
  content: "×";
  margin-left: 8px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.sub-category-item:hover {
  background-color: #f0f0f0;
}

.selected-category {
  margin-top: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.selected-subcategory-item {
  display: inline-flex;
  align-items: center;
  margin: 5px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border-radius: 3px;
}

.selected-subcategory-item button {
  margin-left: 8px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0 4px;
}
